import { rem, TextInput, Tooltip } from "@mantine/core";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useRef } from "react";
import { useFetcher } from "@remix-run/react";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconTrash } from "@tabler/icons-react";

export function UserVideoTitle({ videoData, setIsLoading }) {
  const { t } = useTranslation();
  const [title, setTitle] = useState(videoData.title);
  const [error, setError] = useState("");
  const titleUpdateFetcher = useFetcher();
  const inputRef = useRef(null);
  // let isLoading = titleUpdateFetcher.state === "submitting";
  // Update local state when videoData changes
  useEffect(() => {
    setTitle(videoData.title);
  }, [videoData.title]);

  const handleChange = (event) => {
    setTitle(event.currentTarget.value);
    setError("");
  };

  const handleBlur = () => {
    if (title.length === 0) {
      setError("Title cannot be empty");
      setTitle(videoData.title); // Reset to original title if empty
    } else if (title !== videoData.title) {
      saveTitle();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      inputRef.current?.blur();
    }
  };

  const saveTitle = () => {
    if (title !== videoData.title) {
      setIsLoading(true);
      titleUpdateFetcher.submit(
        { title },
        { method: "post", action: `/videos/update/${videoData.id}` }
      );
    }
  };
  useEffect(() => {
    if (titleUpdateFetcher.data && titleUpdateFetcher.data.success) {
      notifications.show({
        title: "Success",
        message: `Updated video title to ${videoData.title}`,
        color: "teal",
        autoClose: 5000,
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
      });
    } else if (titleUpdateFetcher.data && titleUpdateFetcher.data.error) {
      notifications.show({
        title: "Error",
        message: titleUpdateFetcher.data.error,
        color: "red",
        autoClose: false,
        icon: <IconTrash style={{ width: rem(18), height: rem(18) }} />,
      });
    }
    setIsLoading(false);
  }, [titleUpdateFetcher.data]);

  const textInput = (
    <TextInput
      ref={inputRef}
      variant="unstyled"
      value={title}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      size="xs"
      error={error}
      classNames={{
        input: "title_input",
        wrapper: "title_input__wrapper",
      }}
      styles={(theme) => ({
        input: {
          fontSize: "16px",
          fontWeight: 600,
          padding: 0,
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          width: "100%",
          "&:focus": {
            outline: "none",
          },
        },
        wrapper: {
          width: "100%",
          paddingTop: 0,
        },
      })}
    />
  );

  // Check if text is truncated based on video aspect ratio and title length
  // 15 is the max length for vertical videos
  // 33 is the max length for horizontal videos

  const isTruncated = (title.length > 15 && videoData.videoAspectRatio === "vertical") || (title.length > 33 && videoData.videoAspectRatio === "horizontal");

  return (
    <>
      {isTruncated ? (
        <Tooltip position="bottom-end" label={title}>
          {textInput}
        </Tooltip>
      ) : (
        textInput
      )}
    </>
  );
}
